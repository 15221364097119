import {Button} from "@mui/material";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {apiUrl} from "../../config";

export default function Logout() {
  const [cookies, setCookie] = useCookies(['UserToken']);
  const navigate = useNavigate();
  const uuid = JSON.parse(window.localStorage.getItem('user')).uuid;

  return (
    <Button
      onClick={() => {
        axios.post(apiUrl + '/api/auth/logout', {
          uuid: uuid
        }, {
          headers: {
            "x-access-token": cookies.UserToken,
          }
        })
          .then(() => {
            setCookie("UserToken", null);
            navigate("/");
          })
          .catch(() => {

          });
      }}
    >Logout</Button>
  )
}
