import {Box, Button, Modal, Typography} from "@mui/material";
import React from "react";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Confirm(props) {
  const title = props.title ? props.title : "Confirm";
  const description = props.desc ? props.desc : "Do you want to continue?";
  const option1 = props.opt1 ? props.opt1 : "Yes";
  const option2 = props.opt2 ? props.opt2 : "no";

  return (
    <Modal open={props.open}>
      <Box sx={style}>
        <Typography
          variant="h6"
          component="h2"
        >
          {title}
        </Typography>
        <Typography
          variant={"subtitle2"}
          style={{display: "flex",
            alignItems: "end",
            color: "#858585",
          }}
        >
          {description}
        </Typography>
        <div>
          <Button
            variant="outlined"
            onClick={() => {props.onConfirm()}}
            sx={{mr: 1}}
          >
            {option1}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {props.onDenied()}}
          >
            {option2}
          </Button>
        </div>
      </Box>
    </Modal>
  )
}