import React, {useEffect, useState} from "react";
import {
  Alert,
  Box,
  Button, Divider,
  Typography
} from "@mui/material";
import * as PropTypes from "prop-types";
import ProjectEditForm from "./form/ProjectEditForm";
import VersionTab from "../version/VersionTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export default function ProjectTabs(props)
{
  const [project, setProject] = useState(props.project);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setProject(props.project);
  }, [props]);

  return (
    <div>
      <Box sx={{mb: 3}}>
        {project.beta ? <Alert severity="warning">This project is currently in beta!</Alert> : <></>}
        <div style={{display: "flex", flexDirection: "row"}}>
          <Typography
            variant={"h3"}
            style={{marginRight: "1rem"}}
          >
            {project.name}
          </Typography>
          <Typography
            variant={"subtitle2"}
            style={{display: "flex",
              alignItems: "end",
              color: "#858585",}}
          >
            {project.uuid}
          </Typography>
        </div>
        <Typography variant={"subtitle1"}>{project.description}</Typography>
        <Button
          variant={"contained"}
          onClick={() => handleOpen()}
        >Edit</Button>
        <ProjectEditForm
          open={open}
          handleClose={() => handleClose()}
          element={project}
          onSuccess={(project) => {
            setProject(project);
            props.update();
          }}
        />
      </Box>
      <Divider/>
      <VersionTab
        project={project}
      />
    </div>
  )
}