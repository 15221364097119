import {
  Alert,
  AlertTitle,
  Box,
  Modal,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import {useCookies} from "react-cookie";
import axios from "axios";
import {apiUrl} from "../../config";
import {LoadingButton} from "@mui/lab";
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import SaveIcon from "@mui/icons-material/Save";
import CopyKey from "./CopyKey";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function Form(props) {
  const [cookies] = useCookies(['UserToken']);
  const [error, setError] = useState(null);
  const [state, setState] = React.useState({
    name: "",
    desc: ""
  });
  const [loading, setLoading] = useState(false);

  function createProject() {
    if (state.name === "") {
      setError("All fields must be filled!");
      return;
    }
    setLoading(true);
    axios.post(apiUrl + '/api/key/', {
      name: state.name,
      description: state.desc
    }, {
      headers: {
        "x-access-token": cookies.UserToken,
      }
    })
      .then((data) => {
        props.setOpen(true);
        props.endCallBack(data.data.message);
      }).catch((errors) => {
      console.log(errors);
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <Box style={{marginTop: "1rem", display: "flex", flexDirection: "column"}}>
      {error &&
        <Alert
          severity="error"
          onClose={() => {
            setError(null)
          }}
          sx={{mb: 1}}
        >
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      }
      <TextField
        error={error !== null}
        required
        sx={{mb: 2}}
        id="outlined-name"
        label="Name"
        defaultValue={state.name}
        onChange={(event) => {
          setState({
            ...state,
            name: event.target.value,
          })
        }}
      />
      <TextField
        sx={{mb: 2}}
        id="outlined-desc"
        label="Description"
        defaultValue={state.desc}
        onChange={(event) => {
          setState({
            ...state,
            desc: event.target.value,
          })
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label='Expiration'/>
      </LocalizationProvider>
      <div>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon/>}
          variant="outlined"
          onClick={() => {
            createProject()
          }}
        >
          Create
        </LoadingButton>
      </div>
    </Box>
  )
}

export default function ApiKeyForm(props) {
  const [open, setOpen] = React.useState(false);
  const [openLink, setOpenLink] = React.useState(false);
  const [link, setLink] = React.useState("Hello");

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.handleClose("addForm", false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
             component="form"
             noValidate
             autoComplete="off">
          <Typography variant="h6" component="h2">Create a new project</Typography>
          <Form
            setOpen={setOpen}
            endCallBack={(link) => {
              props.handleClose("addForm", false);
              setOpenLink(true)
              setLink(link)
            }}
          />
        </Box>
      </Modal>
      {link &&
        <CopyKey
          open={openLink}
          handleClose={setOpenLink}
          keyLink={link}
        />
      }
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Key created!"
      />
    </div>
  )
}