import React, {useEffect, useState} from "react";
import logo from "../resouces/sw_logo.svg"
import {IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {AccountCircle, Visibility, VisibilityOff} from "@mui/icons-material";
import KeyIcon from '@mui/icons-material/Key';
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import axios from "axios";
import {apiUrl} from "../config";
import SnackBar from "../component/utils/SnackBar";

export default function LandingView() {
  const [data, setData] = useState({username: "", password: ""});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [cookies, setCookie] = useCookies(['UserToken']);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function login() {
    if (data.password === "" || data.username === "") {
      setData({
        ...data,
        username: (data.username === "" ? "e" : data.username),
        password: (data.password === "" ? "e" : data.password)
      })
      return;
    }
    setLoading(true);
    axios.post(apiUrl + '/api/auth/login', {
      userName: data.username,
      password: data.password,
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((user) => {
        cookies.UserToken = "";
        setCookie("UserToken", user.data.message.token, {path: '/'});
        window.localStorage.setItem('user', JSON.stringify(user.data.message));
        setLoading(false);
        navigate("/dashboard")
      }).catch((errors) => {
      setLoading(false);
      setError(errors.response.data.message);
    })
  }
  useEffect(() => {
    const keyDownHandler = async (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        login();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  return (
    <div style={{
      background: "linear-gradient(90deg, #172E4D 0%, #234D85 100%)",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "row"
    }}>
      <div style={{
        width: "50%",
        display: "flex",
        alignItems: "center",
        minHeight: "100vh"
      }}>
        <img
          src={logo}
          alt="logo"
          style={{
            display: "flex",
            position: "relative",
            left: "50%",
            translate: "-50%"
          }}
        />
      </div>
      <div style={{
        width: "50%",
        marginRight: "5%",
        marginLeft: "5%"

      }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            marginTop: "13%"
          }}
        >
          <Typography variant="h1">
            Welcome
          </Typography>
          <Typography variant="h1">
            aboard
          </Typography>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "10%"
        }}>
          <TextField
            id="username"
            variant="filled"
            label="Username"
            disabled={loading}
            error={data.username === "e"}
            helperText={data.username === "e" ? "Username cannot be empty" : ""}
            onChange={(event) => {
              setData({
                ...data,
                username: event.target.value,
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle/>
                </InputAdornment>
              )
            }}
            sx={{
              mt: 1,
              mx: 15,
            }}
          />
          <TextField
            id="password"
            variant="filled"
            type={showPassword ? "text" : "password"}
            disabled={loading}
            label="Password"
            error={data.password === "e"}
            helperText={data.password === "e" ? "Password cannot be empty" : ""}
            onChange={(event) => {
              setData({
                ...data,
                password: event.target.value,
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon/>
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              )
            }}
            sx={{
              mt: 2,
              mx: 15,
            }}
          />
          <LoadingButton
            variant="contained"
            loading={loading}
            sx={{
              mt: 3,
              mx: 30
            }}
            onClick={() => {
              login()
            }}
            color="primary"
          >LogIn</LoadingButton>
        </div>
      </div>
      <SnackBar
        message={error}
        open={error !== ""}
        CloseCallBack={() => {
          setError("")
        }}
      />
    </div>
  )
}
