import React from "react";
import {Box, Modal, Typography} from "@mui/material";
import Register from "../component/auth/Register";
import {Navigate} from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function RegisterView() {
  console.log(process.env.REACT_APP_REGISTER_ENABLE)
  return (
    <div>
      {process.env.REACT_APP_REGISTER_ENABLE === "1" ?
        <Modal open={true}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">Create an account</Typography>
            <Register/>
          </Box>
        </Modal>
        :
        <Navigate to='/'/>
      }
    </div>
  )
}