import React from "react";
import {Box, Checkbox, FormControlLabel, Modal, Snackbar, TextField, Typography} from "@mui/material";
import axios from "axios";
import {useCookies} from "react-cookie";
import SaveIcon from "@mui/icons-material/Save";
import {LoadingButton} from "@mui/lab";
import {apiUrl} from "../../../config";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function Form(props) {
  const [state, setState] = React.useState({ // Me suis arreter ici
    uuid: props.element.uuid,
    projectUuid: props.element.projectUuid,
    name: props.element.name,
    desc: props.element.description,
    beta: props.element.beta,
    createdAt: props.element.createdAt,
    updatedAt:props.element.updatedAt
  });
  const [loading, setLoading] = React.useState(false);
  const [cookies] = useCookies(['UserToken']);

  function updateProject() {
    setLoading(true);
    axios.patch(apiUrl + '/api/version/' + state.uuid, {
      projectUuid: state.projectUuid,
      name: state.name,
      description: state.desc,
      beta: state.beta,
    }, {
      headers: {
        "x-access-token": cookies.UserToken,
      }
    })
      .then(() => {
        props.setOpen(true);
        props.handleClose();
      }).catch((errors) => {
      console.log(errors);
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <Box style={{marginTop: "1rem", display: "flex", flexDirection: "column"}}>
      <TextField
        sx={{mt: 3}}
        id="outlined-read-only-uuid"
        label="Project Uuid"
        defaultValue={state.projectUuid}
      />
      <TextField
        sx={{mt: 3}}
        id="outlined-name"
        label="Name"
        defaultValue={state.name}
        onChange={(event) => {
          setState({
            ...state,
            name: event.target.value,
          })}}
      />
      <TextField
        sx={{mt: 3}}
        id="outlined-desc"
        label="Description"
        defaultValue={state.desc}
        onChange={(event) => {
          setState({
            ...state,
            desc: event.target.value,
          })}}
      />
      <FormControlLabel
        control={
          <Checkbox checked={state.beta} onChange={(event) => {
            setState({
              ...state,
              beta: event.target.checked,
            })
          }} name="beta" />
        }
        label="Beta"
      />
      <div>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          onClick={() => {updateProject()}}
          sx={{mr: 1}}
        >
          Save
        </LoadingButton>
      </div>
    </Box>
  )
}

export default function VersionEditForm(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
             component="form"
             noValidate
             autoComplete="off">
          <Typography variant="h6" component="h2">Update a version</Typography>
          <Typography
            variant={"subtitle2"}
            style={{display: "flex",
              alignItems: "end",
              color: "#858585",}}
          >
            {props.element.uuid}
          </Typography>
          <Form
            setOpen={setOpen}
            handleClose={props.handleClose}
            element={props.element}
          />
        </Box>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Version updated!"
      />
    </div>
  )
}
