import React from "react";
import {Avatar, Button, Typography} from "@mui/material";

export default function ProjectComponent(props)
{
  const element = props.project;

  return (
    <div>
      <Button
        onClick={() => props.callbackProject("currentProject", element)}
        style={{textTransform: "initial", alignItems: "initial", minWidth: 280, maxWidth: 280, display: "block", alignContent: "start"}}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
          key={props.index}
        >
          <Avatar
            style={{width: 80, height: 80}}
          >{element.name}</Avatar>
          <div style={{ marginLeft: "1rem"}}
          >
            <Typography variant={"h6"}>{element.name}</Typography>
          </div>
        </div>
      </Button>
    </div>
  )
}