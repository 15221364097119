import React, {useState} from "react";
import {Alert, Slide, Snackbar} from "@mui/material";

function TransitionSlide(props) {
  return <Slide {...props} direction="down"/>;
}

export default function SnackBar(props) {
  const [state] = useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const {vertical, horizontal} = state;

  const handleClose = () => {
    props.CloseCallBack();
  };

  return (
    <Snackbar
      anchorOrigin={{vertical, horizontal}}
      open={props.open}
      onClose={handleClose}
      key={vertical + horizontal}
      TransitionComponent={TransitionSlide}
      autoHideDuration={6000}
    >
      <Alert onClose={handleClose} severity="error">
        {props.message || "Default message"}
      </Alert>
    </Snackbar>
  )
}
