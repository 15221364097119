import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VersionForm from "./form/VersionEditForm";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useCookies} from "react-cookie";
import VersionAddForm from "./form/VersionAddForm";
import Confirm from "../utils/Confirm";
import {apiUrl} from "../../config";

export default function VersionTab(props) {
  const [project, setProject] = useState(props.project);
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [openDelete, setOpenDelete] = useState({open: false, uuid: null});
  const [versions, setVersions] = useState({message: []});
  const [versionForm, setVersionForm] = useState({version: null, open: false});
  const [cookies] = useCookies(['UserToken']);

  function getVersions() {
    setVersions({message: []});
    axios.get(apiUrl + '/api/version/' + props.project.uuid, {
      headers: {
        "x-access-token": cookies.UserToken,
      }
    })
      .then((data) => {
        setVersions(data.data);
      }).catch((errors) => {
      console.log(errors);
      //setError({state: true, message: errors});
    })
  }

  function deleteVersion(setVersions, versionUuid) {
    axios.delete(apiUrl + '/api/version/' + versionUuid, {
      headers: {
        "x-access-token": cookies.UserToken,
      }
    }).then(() => {
      getVersions(setVersions)
    }).catch((errors) => {
      console.log(errors);
      //setError({state: true, message: errors});
    }).finally(() => {
      setloading(false);
    })
  }

  useEffect(() => {
    setProject(props.project);
    getVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.project]);

  return (
    <div>
      <Typography variant={"h5"}>Version</Typography>
      <Button
        variant="contained"
        color="success"
        onClick={() => {setOpen(true)}}
      >Add new Version</Button>
      <VersionAddForm
        open={open}
        handleClose={() => {setOpen(false)}}
        project={project}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell align="right" >Description</TableCell>
              <TableCell align="right" >Uuid</TableCell>
              <TableCell align="right" >Beta</TableCell>
              <TableCell align="right" >Creation</TableCell>
              <TableCell align="right" >Updated</TableCell>
              <TableCell align="right" >Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {versions.message.map((row) => (
              <TableRow
                key={row.uuid}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.description}</TableCell>
                <TableCell align="right">{row.uuid}</TableCell>
                <TableCell align="right">{row.beta ? "yes" : "no"}</TableCell>
                <TableCell align="right">{row.createdAt}</TableCell>
                <TableCell align="right">{row.updatedAt}</TableCell>
                <TableCell align="right">
                  <IconButton
                    disabled={loading}
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={() => {setVersionForm({version: row, open: true})}}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    disabled={loading}
                    color="primary"
                    aria-label="add to shopping cart"
                    onClick={() => {setOpenDelete({open: true, uuid: row.uuid})}}
                  >
                    <DeleteIcon />
                  </IconButton>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {versionForm.version &&
        <VersionForm
          element={versionForm.version}
          handleClose={() => {setVersionForm({...versionForm, open: false})}}
          open={versionForm.open}
        />
      }
      <Confirm
        open={openDelete.open}
        onConfirm={() => {
          setloading(true);
          deleteVersion(setVersions, openDelete.uuid);
          setOpenDelete({open: false, uuid: null});
        }}
        onDenied={() => {setOpenDelete({open: false, uuid: null})}}
      />
    </div>
  )
}
