import React, {useEffect, useState} from "react";
import {Box, Checkbox, FormControlLabel, Modal, Snackbar, TextField, Typography} from "@mui/material";
import axios from "axios";
import {useCookies} from "react-cookie";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import {LoadingButton} from "@mui/lab";
import Confirm from "../../utils/Confirm";
import {apiUrl} from "../../../config";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ProjectEditForm(props) {
  const [state, setState] = React.useState({
    uuid: null,
    name: null,
    desc: null,
    data: null,
    beta: null
  });
  const [open, setOpen] = React.useState(false);
  const [cookies] = useCookies(['UserToken']);
  const [loading, setLoading] = useState({edit: false, delete: false});
  const [del, setDel] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function updateProject() {
    setLoading({...loading, edit: true});
    axios.patch(apiUrl + '/api/project/' + state.uuid, {
      name: state.name,
      description: state.desc,
      data: state.data,
      beta: state.beta,
    }, {
      headers: {
        "x-access-token": cookies.UserToken,
      }
    })
      .then((data) => {
        setOpen(true);
        props.handleClose();
        props.onSuccess(data.data);
      })
      .catch((errors) => {console.log(errors);})
      .finally(() => {setLoading({...loading, edit: false});})

  }

  function deleteProject() {
    setLoading({...loading, delete: true});
    axios.delete(apiUrl + '/api/project/' + state.uuid, {
      headers: {
        "x-access-token": cookies.UserToken,
      }
    })
      .then(() => {
        setOpen(true);
        props.handleClose();
        props.onSuccess(null);
      })
      .catch((errors) => {console.log(errors);})
      .finally(() => {setLoading({...loading, delete: false});})
  }

  useEffect(() => {
    setState({
      uuid: props.element.uuid,
      name: props.element.name,
      desc: props.element.description,
      data: props.element.data,
      beta: props.element.beta,
    })
  }, [props]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
             component="form"
             noValidate
             autoComplete="off">
          <div style={{display: "flex", flexDirection: "row"}}>
            <Typography
              variant={"h3"}
              style={{marginRight: "1rem"}}
            >
              {state.name}
            </Typography>
            <Typography
              variant={"subtitle2"}
              style={{display: "flex",
                alignItems: "end",
                color: "#858585",}}
            >
              {state.uuid}
            </Typography>
          </div>
          <div
            style={{marginTop: "1rem", display: "flex", flexDirection: "column"}}
          >
            <TextField
              sx={{mt: 3}}
              id="outlined-name"
              label="Name"
              defaultValue={state.name}
              onChange={(event) => {
                setState({
                  ...state,
                  name: event.target.value,
                })}}
            />
            <TextField
              sx={{mt: 3}}
              id="outlined-desc"
              label="Description"
              defaultValue={state.desc}
              onChange={(event) => {
                setState({
                  ...state,
                  desc: event.target.value,
                })}}
            />
            <TextField
              sx={{mt: 3}}
              id="outlined-desc"
              label="table name"
              defaultValue={state.data}
              onChange={(event) => {
                setState({
                  ...state,
                  data: event.target.value,
                })}}
            />
            <FormControlLabel
              control={
              <Checkbox checked={state.beta} onChange={(event) => {
                setState({
                  ...state,
                  beta: event.target.checked,
                })
              }} name="beta" />
            }
              label="Beta"
            />
            <div>
              <LoadingButton
                loading={loading.edit}
                disabled={loading.delete}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
                onClick={() => {updateProject()}}
                sx={{mr: 1}}
              >
                Save
              </LoadingButton>
              <LoadingButton
                loading={loading.delete}
                disabled={loading.edit}
                loadingPosition="start"
                startIcon={<DeleteIcon />}
                variant="outlined"
                onClick={() => {setDel(true)}}
              >
                Delete
              </LoadingButton>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Project updated!"
      />
      <Confirm
        open={del}
        onConfirm={() => {
          setDel(false);
          deleteProject();
        }}
        onDenied={() => {setDel(false)}}
      />
    </div>
  )
}
