import React from "react";
import {Backdrop, CircularProgress, Typography} from "@mui/material";
import ProjectComponent from "./ProjectComponent";

export default function ProjectList(props)
{
  return (
    <div style={{ display: "flex",
      flexDirection: "column",
      position: "relative",
      marginRight: "1rem"
    }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute", width: "inherit", height: "inherit"
        }}
        open={!props.list}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {props.list.length === 0 &&
        <Typography variant={"subtitle1"}>No project found.</Typography>
      }
      {props.list &&
        props.list.map((element, index) => {
          return (
            <ProjectComponent
              project={element}
              index={index}
              key={index}
              callbackProject={props.callbackProject}
            />
          );
        })
      }
    </div>
  )
}