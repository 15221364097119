import {
  Box, CssBaseline,
} from "@mui/material";
import TaskIcon from '@mui/icons-material/Task';
import KeyIcon from '@mui/icons-material/Key';
import {useEffect, useState} from "react";
import ProjectView from "./ProjectsView";
import LandingView from "./LandingView";
import DashboardDrawer from "../component/dashboard/DashboardDrawer";
import {ProtectedRoute} from "../component/utils/ProtectedRoute";
import {useCookies} from "react-cookie";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {apiUrl} from "../config";
import ApiKey from "./ApiKey";

export default function DashBoard() {
  const [auth, setAuth] = useState(false);
  const list = [{
    name: "Project",
    icon: <TaskIcon/>,
    content: <ProjectView/>
  }, {
    name: "Access Token",
    icon: <KeyIcon/>,
    content: <ApiKey/>
  }]
  const [selectedElement, setElement] = useState(list[0]);
  const [cookies] = useCookies(['UserToken']);
  const navigate = useNavigate();
  function authenticate() {
    axios.post(apiUrl + '/api/auth', {}, {
      headers: {
        "x-access-token": cookies.UserToken,
      }
    })
      .then(() => {
        setAuth(true);
      }).catch((errors) => {
      if (errors.response.status === 403) {
        navigate("/");
      }
    })
  }

  useEffect(() => {
    authenticate();
  });

  return (
    <ProtectedRoute
      auth={auth}
    >
      <Box sx={{display: "flex"}}>
        <CssBaseline />
        <DashboardDrawer
          onSelection={setElement}
          list={list}
        />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {selectedElement.content}
        </Box>
      </Box>
    </ProtectedRoute>
  )
}
