import React, {useState} from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import {useCookies} from "react-cookie";
import {LoadingButton} from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import {apiUrl} from "../../../config";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function Form(props) {
  const [cookies] = useCookies(['UserToken']);
  const [error, setError] = useState(null);
  const [state, setState] = React.useState({
    name: "",
    desc: "",
    beta: false
  });
  const [loading, setLoading] = useState(false);

  function createProject() {
    if (state.name === "") {
      setError("All fields must be filled!");
      return;
    }
    setLoading(true);
    axios.post(apiUrl + '/api/project/', {
      name: state.name,
      description: state.desc,
      beta: state.beta,
    }, {
      headers: {
        "x-access-token": cookies.UserToken,
      }
    })
      .then(() => {
        props.setOpen(true);
        props.handleClose("addForm", false);
      }).catch((errors) => {
      console.log(errors);
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <Box style={{marginTop: "1rem", display: "flex", flexDirection: "column"}}>
      {error &&
        <Alert
          severity="error"
          onClose={() => {setError(null)}}
          sx={{mb: 1}}
        >
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      }
      <TextField
        error={error !== null}
        sx={{mb: 2}}
        id="outlined-name"
        label="Name"
        defaultValue={state.name}
        onChange={(event) => {
          setState({
            ...state,
            name: event.target.value,
          })}}
      />
      <TextField
        sx={{mb: 2}}
        id="outlined-desc"
        label="Description"
        defaultValue={state.desc}
        onChange={(event) => {
          setState({
            ...state,
            desc: event.target.value,
          })}}
      />
      <FormControlLabel
        control={
          <Checkbox checked={state.beta} onChange={(event) => {
            setState({
              ...state,
              beta: event.target.checked,
            })
          }} name="beta" />
        }
        label="Beta"
      />
      <div>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          variant="outlined"
          onClick={() => {createProject()}}
        >
          Create
        </LoadingButton>
      </div>
    </Box>
  )
}

export default function ProjectAddForm(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {props.handleClose("addForm", false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
             component="form"
             noValidate
             autoComplete="off">
          <Typography variant="h6" component="h2">Create a new project</Typography>
          <Form
            setOpen={setOpen}
            handleClose={props.handleClose}
          />
        </Box>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Project created!"
      />
    </>
  )
}
