import React, {useEffect, useState} from "react";
import {DataGrid} from '@mui/x-data-grid';
import axios from "axios";
import {apiUrl} from "../config";
import {Alert, AlertTitle, Box, Button, Divider, Snackbar, Typography} from "@mui/material";
import ApiKeyForm from "../component/apikey/ApiKeyForm";
import Logout from "../component/auth/Logout";
import {useCookies} from "react-cookie";

function Toolbar(props) {
  const [numberComp, setNumberComp] = useState(0)
  const [cookies] = useCookies(['UserToken']);
  const [error, setError] = useState("");
  let keys = props.list;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  function deleteKeys(endCallback)
  {
    setNumberComp(0);
    props.list.forEach((key) => {
      axios.delete(apiUrl + '/api/key/' + key, {
        headers: {
          "x-access-token": cookies.UserToken,
        }
      }).then(() => {
      }).catch((errors) => {
        console.log(errors);
        endCallback();
        setError("Unable to delete key(s).");
      })
    })
  }

  useEffect(() => {
    console.log(numberComp)
    if (keys.length === 0)
      console.log('done')
  }, [numberComp])

  return (
    <div>
      <Snackbar
        open={error !== ""}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity="error"
          onClose={() => {
            setError(null)
          }}
          sx={{mb: 1}}
        >
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      </Snackbar>
      <Button
        onClick={() => {
          props.openCreationForm();
        }}
      >
        Create key
      </Button>
      <Button
        onClick={() => {
          deleteKeys(() => {
            console.log("Callback trigger")
            setNumberComp(numberComp + 1);
          })
        }}
        disabled={props.list.length <= 0}
      >
        Delete
      </Button>
    </div>
  )
}

export default function ApiKey() {
  const [data, setData] = useState({
    error: {
      state: false,
      message: ""
    },
    keys: [],
    addForm: false,
    currentProject: null,
  })
  const columns = [
    {field: 'id', headerName: 'ID', width: 70},
    {field: 'name', headerName: 'Name', width: 130},
    {field: 'description', headerName: 'Description', width: 130},
    {field: 'expiration', headerName: 'Expiration', width: 160},
    {field: 'scope', headerName: 'Scope', width: 160},
  ];
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [cookies] = useCookies(['UserToken']);

  function updateData(name, value) {
    setData({
      ...data,
      [name]: value,
    })
  }

  useEffect(() => {
    axios.get(apiUrl + '/api/key', {
      headers: {
        'x-access-token': cookies.UserToken
      }
    }).then((response) => {
      const keys = []
      response.data.message.forEach((key) => {
        keys.push({
          id: key.id,
          name: key.name,
          description: (key.description === '' ? 'none' : key.description),
          expiration: key.expiration || 'none',
          scope: key.scope || 'none'
        })
      })
      updateData('keys', keys)
    })
  }, [])
  function openForm () {
    updateData("addForm", true)
  }

  return (
    <div style={{height: 400, width: '100%'}}>
      <Typography
        variant='h3'
        sx={{mb: 2}}
      >
        ApiKey
      </Typography>
      <Divider/>
      <Box sx={{mb: 3, mt: 1}}>
        <Logout/>
      </Box>
      <DataGrid
        rows={data.keys}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {page: 0, pageSize: 5},
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        slots={{
          toolbar: Toolbar,
        }}
        slotProps={{
          toolbar: {
            openCreationForm: openForm,
            list: rowSelectionModel
          }
        }}
      />
      <ApiKeyForm
        open={data.addForm}
        handleClose={updateData}
      />
    </div>
  )
}