import {createTheme} from "@mui/material";

export const defaultTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#102036",
      dark: "#102036",
      contrastText: "#fff"
    },
    background: {
      default: "#172E4D",
      paper: "#172E4D"
    }
  },
  typography: {
    h1: {
      color: "#FFF"
    }
  }
});
