import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {CookiesProvider} from "react-cookie";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import LandingView from "./view/LandingView";
import RegisterView from "./view/RegisterView";
import DashBoard from "./view/DashBoard";
import {ThemeProvider} from "@mui/material";
import {defaultTheme} from "./theme/DefaultTheme"
import NotFound from "./view/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingView/>
  },
  {
    path: '/dashboard',
    element: <DashBoard/>
  },
  {
    path: '/register',
    element: <RegisterView/>
  },
  {
    path: '/*',
    element: <NotFound/>
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <CookiesProvider>
        <RouterProvider router={router}/>
      </CookiesProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
