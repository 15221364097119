import {
  Alert,
  Box, Button,
  Modal,
  TextField,
  Typography
} from "@mui/material";
import React, {useState} from "react";
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function Form(props) {
  const [copy, setCopy] = useState(false);
  return (
    <Box style={{marginTop: "1rem", display: "flex", flexDirection: "column"}}>
      <Alert severity="error">Be careful you cannot read again your key once this window closed</Alert>
      <TextField
        sx={{mb: 2, mt: 2}}
        label="You api key"
        defaultValue={props.keyLink}
        InputProps={{
          readOnly: true,
        }}
      />
      <div>
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(props.keyLink).then(() => {
              setCopy(true);
            });
          }}
        >
          {copy ? 'Copied!' : 'Copy'}
        </Button>
        <Button
          startIcon={<CloseIcon/>}
          onClick={() => {
            props.handleClose(false);
          }}
        >
          Close
        </Button>
      </div>
    </Box>
  )
}

export default function CopyKey(props) {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => {
          props.handleClose(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}
             component="form"
             noValidate
             autoComplete="off">
          <Typography variant="h6" component="h2">Copy you key</Typography>
          <Form
            keyLink={props.keyLink}
            handleClose={props.handleClose}
          />
        </Box>
      </Modal>
    </div>
  )
}