import React, {useState} from 'react';
import {Alert, AlertTitle, Backdrop, Button, CircularProgress, TextField} from "@mui/material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {apiUrl} from "../../config";

export default function Register()
{
  const [data, setData] = useState({username: "", password: ""});
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function login() {
    if (data.password === "" || data.username === "") {
      setErrorMsg("All fields must be filled!");
      return;
    }
    setLoading(true);
    axios.post(apiUrl + '/api/account/', {
      userName: data.username,
      password: data.password,
    }, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(() => {
        setLoading(false);
        navigate("/")
      }).catch((errors) => {
      setLoading(false);
      setErrorMsg(errors.response.data.message);
    })
  }

  return (
    <div
      style={{marginTop: "1rem", display: "flex", flexDirection: "column"}}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {errorMsg && <Alert
        severity="error"
        onClose={() => {setErrorMsg(null)}}
        sx={{mb: 1}}
      >
        <AlertTitle>Error</AlertTitle>
        {errorMsg}
      </Alert>
      }
      <TextField
        error={errorMsg !== null}
        id="outlined-name"
        label="Username"
        onChange={(event) => {
          setData({
            ...data,
            username: event.target.value,
          })}}
        sx={{mt: 1}}
      />
      <TextField
        error={errorMsg !== null}
        id="outlined-name"
        label="Password"
        type="password"
        onChange={(event) => {
          setData({
            ...data,
            password: event.target.value,
          })}}
        sx={{mt: 3}}
      />
      <div>
        <Button
          variant="contained"
          onClick={() => {login()}}
          sx={{mt: 3, bgcolor: "green"}}
        >Confirm</Button>
        <Button
          variant="contained"
          onClick={() => {navigate('/')}}
          sx={{mt: 3, ml: 2, bgcolor: "red"}}
        >Cancel</Button>
      </div>
    </div>
  )
}
