import React, {useEffect, useState} from "react";
import {Alert, Box, Button, Divider, Typography} from "@mui/material";
import ProjectAddForm from "../component/project/form/ProjectAddForm";
import axios from "axios";
import ProjectList from "../component/project/ProjectList";
import ProjectTabs from "../component/project/ProjectTabs";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import Logout from "../component/auth/Logout";
import {apiUrl} from "../config";

export default function ProjectView()
{
  const [data, setData] = useState({
    error: {
      state: false,
      message: ""
    },
    projects: [],
    addForm: false,
    currentProject: null,
  })
  const [cookies] = useCookies(['UserToken']);
  const navigate = useNavigate();

  function updateData(name, value) {
    setData({
      ...data,
      [name]: value,
    })
  }
  function getProjects() {
    updateData("currentProject", null);
    axios.get(apiUrl + '/api/project', {
      headers: {
        "x-access-token": cookies.UserToken,
      }
    })
      .then((datas) => {
        updateData("projects", datas.data.message);
      }).catch((errors) => {
        if (errors.response.status === 403)
          navigate("/");
        updateData("error", {state: true, message: errors})
    })
  }

  useEffect(() => {
    if (data.addForm)
      return;
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.addForm]);

  return (
      <div>
        <Typography
          variant={"h3"}
          sx={{mb: 2}}
        >
          Projects
        </Typography>
        <Divider/>
        <Box sx={{mb: 3, mt: 1}}>
          <Button
            onClick={() => getProjects(updateData)}
          >Refresh</Button>
          <Button
            onClick={() => {updateData("addForm", true)}}
          >Add project</Button>
          <Logout/>
        </Box>
        {data.error.state && <Alert sx={{mb: 1}} severity="error">An error occur on the request: {data.error.message} </Alert>}
        <div style={{display: "flex"}}>
          <ProjectList
            list={data.projects}
            callbackProject={updateData}
          />
          {data.currentProject &&
            <ProjectTabs
              project={data.currentProject}
              update={getProjects}
            />
          }
        </div>
        <ProjectAddForm
          open={data.addForm}
          handleClose={updateData}
        />
      </div>
  );
}
